.carousel-item-padding {
    padding: 0 10px;
}

.carousel-item {
    display: flex;
    justify-content: center;
}

.carousel-image {
    width: 100%;
    height: auto;
    max-height: 704px;
    object-fit: cover;
}
